import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AuthLayout from '../layouts/authLayout/AuthLayout';
//import DefaultLayout from '../layouts/default/Layout';
import SignLayout from '../layouts/SignLayout/Layout';
import Dashboard from '../pages/dashboard/Dashboard';
import Login from '../pages/login/Login';
import Users from '../pages/users/Users';
import { AuthAction } from "../redux/actions";
import { AppConstant, AuthConstant } from "../redux/reducers";
import ApplicationHome from "../pages/applications/ApplicationHome";
import ApplicationDashboard from "../pages/applications/ApplicationDashboard";
import ApplicationCreate from "../pages/applications/ApplicationCreate";
import ApplicationVersionEdit from "../pages/applications/versions/ApplicationVersionEdit";
import ApplicationVersionView from "../pages/applications/versions/ApplicationVersionView";
import ApplicationVersionCreate from "../pages/applications/versions/ApplicationVersionCreate";
import ApiSandbox from "../pages/sandbox/ApiSandbox";
import ApiSandboxAdmin from "../pages/sandboxAdmin/ApiSandboxAdmin";
import AppTemplates from "../pages/applications/templates/AppTemplates";
import AppCMS from "../pages/applications/cms/AppCMS";
import AppTemplate from "../pages/applications/templates/AppTemplate";
import AppTemplatesCreate from "../pages/applications/templates/AppTemplatesCreate";

import TopMenu from '../components/topmenu';
import TopAside from '../components/aside';
import Footer from '../components/footer';

import E404 from '../pages/e404';
import Home from '../pages/home';
import Doc from '../pages/doc';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RAYModal from "../components/common/RAYModal";
import RAYButton from "../components/common/RAYButton";
import { useEffect, useState } from "react";
import SettingHomepageApps from "../components/application/SettingHomepageApps";
import EmailAlias from "../pages/emailAlias/EmailAlias";

const token = localStorage.getItem('token');
const authToken = localStorage.getItem('authToken');

const AppRouter = () => {
    const dispatch = useDispatch();
    const [waiting, setWaiting] = useState(false);
    const swUpdate = useSelector(state => state.AppReducer.swUpdate);
    if (token && authToken) {
        dispatch({ type: AuthConstant.SET_IS_LOGIN, payload: true });
        dispatch(AuthAction.GetMe());
    }

    return <>
        <div className="layout-container">
            <BrowserRouter>
            <TopMenu />
            <div className="layout-page">
                <div className="content-wrapper">
                    <TopAside />
                    <div className="container-fluid flex-grow-1 container-p-y">
                        <Routes>
                            <Route path="/dashboard" element={<AuthLayout><Dashboard /></AuthLayout>} />
                            <Route path="/users" element={<AuthLayout><Users /></AuthLayout>} />
                            <Route path="/email-alias" element={<AuthLayout><EmailAlias /></AuthLayout>} />
                            <Route path="/users/:sub" element={<AuthLayout><Users /></AuthLayout>} />
                            <Route path="/login" element={<SignLayout><Login /></SignLayout>} />/
                            <Route path="/applications/create" element={<AuthLayout><ApplicationCreate /></AuthLayout>} />/
                            <Route path="/applications/_homepage_assets" element={<AuthLayout><SettingHomepageApps /></AuthLayout>} />/
                            <Route path="/applications/:appName/versions" element={<AuthLayout><ApplicationVersionCreate /></AuthLayout>} />/
                            <Route path="/applications/:appName/versions/:version" element={<AuthLayout><ApplicationVersionView /></AuthLayout>} />/
                            <Route path="/applications/:appName/versions/:version/edit" element={<AuthLayout><ApplicationVersionEdit /></AuthLayout>} />/
                            <Route path="/applications/:appName/cms/:version?" element={<AuthLayout><AppCMS /></AuthLayout>} />/
                            <Route path="/applications/:appName/templates" element={<AuthLayout><AppTemplates /></AuthLayout>} />/
                            <Route path="/applications/:appName/templates/:version" element={<AuthLayout><AppTemplate /></AuthLayout>} />/
                            <Route path="/applications/:appName/templates/create" element={<AuthLayout><AppTemplatesCreate /></AuthLayout>} />/
                            <Route path="/applications/:appName/:tabcode?" element={<AuthLayout><ApplicationHome /></AuthLayout>} />/
                            <Route path="/applications" element={<AuthLayout><ApplicationDashboard /></AuthLayout>} />/
                            <Route path="/sandbox" element={<AuthLayout><ApiSandbox /></AuthLayout>} />/
                            <Route path="/apiSandboxAdmin" element={<AuthLayout><ApiSandboxAdmin /></AuthLayout>} />/
                            {/* <Route path="*" element={<DefaultLayout>Empty</DefaultLayout>} /> */}
                            <Route path="/doc/:path?" element={<Doc />} />
                            <Route path="/err404" element={<E404 />} />
                            <Route path="*" element={<Home />} />
                        </Routes>
                        <ToastContainer position='top-right' className='ray-toast' />
                    </div>
                    <Footer />
                </div>
            </div>
            </BrowserRouter>
        </div>
        <RAYModal />
    </>
}

const mapState = (state) => {
    const isLogin = state.AuthReducer.isLogin;
    return { isLogin };
};

const mapDispatch = (dispatch) => ({
})

export default AppRouter;
