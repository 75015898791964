import _ from "lodash";

const initialState = { 
	emailAlias: [],
};

export const AliasConstant = {
	SET_EMAIL_ALIAS: "ACTION_USER_SET_EMAIL_ALIAS",
	ADD_EMAIL_ALIAS: "ACTION_USER_ADD_EMAIL_ALIAS",
};

export const AliasReducer = (state = initialState, action) => {
    switch (action.type) {
		case AliasConstant.ADD_EMAIL_ALIAS:
			const addEmailAlias = () => {
				const isExist = state.emailAlias.find(x => x.name === action?.item?.name); 
				if (isExist) {
					return state.emailAlias.map(x => x.name === action.item.name ? action.item : x);
				}
				return [...state.emailAlias, action.item];
			}
			return {
				...state,
				emailAlias: addEmailAlias(),
			};
		case AliasConstant.SET_EMAIL_ALIAS:
			return {
				...state,
				emailAlias: action.items,
			};
        default:
            return state
    }
}