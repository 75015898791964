import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ApplicationHook from "../../hooks/ApplicationHook";
import { ApplicationAction, ApplicationVersionAction } from "../../redux/actions";
import RAYTabs from "../common/RAYTabs";
import AppSettingAttrs from "./AppSettingAttrs";
import AppSettingBasic from "./AppSettingBasic";
import AppSettingManuals from "./AppSettingManuals";
import AppSettingTeams from "./AppSettingTeams";
import AppSettingTerms from "./AppSettingTerms";
import AppSettingLang from "./AppSettingLang";
import AppSettingUI from "./AppSettingUI";
import AppSettingBanner from "./AppSettingBanner";
import AppSettingActivityLog from "./AppSettingActivityLog";
import AppSettingAnal from "./AppSettingAnal";
import AppSettingConfig from "./AppSettingConfig";
import AppSettingErrors from "./AppSettingErrors";
import AppSettingManualsV2 from "./AppSettingManualsV2";

const RAYTeamsAppName = 'RayLink';

const TABS = {
    BASIC: 'basic',
    TEAMS: 'teams',
    ATTR: 'attributes',
    UI: 'ui',
    MANUALS: 'manuals',
    TERMS: 'terms',
    ACTIVITYLOG : "alog",
    ERROR : "elog",
    REPORT : "report",
    BANNER: "banner",
    CONFIG: "config",
    LANGUAGE: "language",
};

const noneFooterTabs = [
    TABS.MANUALS,
    TABS.ACTIVITYLOG,
    TABS.ERROR,
    TABS.CONFIG,
    TABS.LANGUAGE,
    TABS.REPORT,
];

const ApplicationView = ({ create, auth, item, GetAllApplication, GetApplication, GetApplicationVersions }) => {
    const { appName, tabcode } = useParams();
    const [data, setData] = useState({});
    const [edit, setEdit] = useState(false);
    const { createApp, updateApp, deleteApp } = ApplicationHook();
    const [uploadHooks, setUploadHooks] = useState({});
    const [tabs, setTabs] = useState([
        { code: TABS.BASIC, label: 'Basic', disabled: false },
        { code: TABS.TEAMS, label: 'RAYTeams Setting', disabled: false },
        { code: TABS.ATTR, label: 'Arguments', disabled: false },
        { code: TABS.UI, label: 'UI', disabled: false },
        { code: TABS.MANUALS, label: 'Manuals', disabled: false },
        { code: TABS.TERMS, label: 'Terms and Conditions', disabled: false },
        { code: TABS.BANNER, label: 'Banner', disabled: false },
        { code: TABS.REPORT, label: 'Analystics', disabled: false },
        { code: TABS.ACTIVITYLOG, label: 'Logs', disabled: false },
        { code: TABS.CONFIG, label: 'Config', disabled: false },
        { code: TABS.LANGUAGE, label: 'Language', disabled: false },
    ]);
    const [tab, setTab] = useState(tabcode ? tabs.find(x => x.code === tabcode) : tabs[0]);
    const navigator = useNavigate();

    useEffect(() => {
        setTab(tabcode ? tabs.find(x => x.code === tabcode) : tabs[0]);
    }, [tabcode]);

    useEffect(() => {
        GetApplicationVersions(RAYTeamsAppName);
    }, [GetApplicationVersions]);

    useEffect(() => {
        if (create) {
            setData({
                name: '',
                is_active: false,
            });
            setEdit(true);
            const disableCreateTabCodes = [
                TABS.TEAMS, 
                TABS.ATTR, 
                TABS.MANUALS, 
                TABS.TEAMS, 
                TABS.UI, 
                TABS.TERMS, 
                TABS.BANNER, 
                TABS.REPORT,
                TABS.ACTIVITYLOG,
                TABS.ERROR,
                TABS.CONFIG,
                TABS.LANGUAGE,
            ];
            setTabs(prev => prev.map(x => disableCreateTabCodes.includes(x.code) ? { ...x, disabled: true } : x));
        } else {
            setTabs(prev => prev.map(x => ({ ...x, disabled: false })));
        }
    }, [create]);

    const genData = useCallback(() => {
        if (item && item.name) {
            setData({
                name: item.name,
                title: item.title,
                desc: item.desc,
                is_active: item.is_active,
                category: item.category,
                update_force: item.update_force,
                rayteams_apps: item.rayteams_apps,
                built_in: item.built_in,
                auto_run: item.auto_run,
                exe_file_name: item.exe_file_name,
                uninstall_file_name: item.uninstall_file_name,
                is_copy: item.is_copy,
                install_argument: item.install_argument,
                uninstall_argument: item.uninstall_argument,
                use_check_license: item.use_check_license,
                app_attrs: item.app_attrs,
                is_beta: item.is_beta,
                langs: item.langs || [],
                isdev: item.isdev || 'N',
                app_icon: item.app_icon,
                manuals: (item.manuals ?? []),
                // licenseId: item.licenseId,
                hasSetting: item.hasSetting,
                settingHeight: item.settingHeight,
                minTeamsVersion: item.minTeamsVersion,
                relatedUser: item.relatedUser,
                serviceProject: item.serviceProject,
                serviceProjectAssets: { ...(item.serviceProjectAssets || {}) },
                serviceProjectForm: { ...(item.serviceProjectForm || {}) },
                serviceProjectConfig: { ...(item.serviceProjectConfig || {}) },
                showHome: item.showHome,
                exeFolder: item.exeFolder,
                banner: item.banner,
                isPopular: !!item.isPopular,
                popularDesc: item.popularDesc,
                popularImg: item.popularImg || {},
                tags: item?.tags || [],
                relatedApps: item?.relatedApps || [],
                inAppFeature: item?.inAppFeature, // true || false
                parentAppName: item?.parentAppName, // A parent app name if is child app or inAppFeature
                langs: item?.langs || [],
                // manualLangs: item?.manualLangs || [],
                alkey: item.alkey,
                whiteListCC: item.whiteListCC || [],
            });
        }
    }, [item]);

    useEffect(() => {
        genData();
    }, [genData])

    const deleteHandler = useCallback(async () => {
        const ret = await deleteApp(item.name);
        if (ret) {
            navigator('/applications')
        }
    }, [deleteApp, item?.name, navigator]);

    const updateHandler = useCallback(async () => {
        if (create && !data.name) {
            toast('Require Name', { type: 'info' });
            return;
        }
        const uploadFields = Object.keys(uploadHooks);
        const uploads = [];
        for (const uploadField of uploadFields) {
            const uploadSet = uploadHooks[uploadField];
            const _fieldName = uploadSet.fieldName.split(".")[0];
            if (!data[_fieldName]) return;
            if (uploadSet.inArr) {
                if (!data[uploadSet.fieldName].find(x => x.id === uploadSet.inArrId)) return;
            }
            const upload = uploadSet.fnc;
            const fnc = async () => {
                const upRet = await upload();
                if (!uploadSet.inArr) {
                    const nameArr = upRet.name.split(".");
                    if (nameArr.length === 1) {
                        data[upRet.name] = upRet.data;
                        data[upRet.name + '_name'] = upRet.data.file_name;
                    } else if (nameArr.length === 2) {
                        data[nameArr[0]][nameArr[1]] = upRet.data
                    }
                } else {
                    data[uploadSet.fieldName].find(x => x.id === uploadSet.inArrId).file = upRet.data;
                }
            };
            uploads.push(fnc);
        }

        const next = async () => {
            if (create) {
                if (!data.name) {
                    toast('Require Name', { type: 'info' });
                    return;
                }
                const ret = await createApp({ ...data, creator: auth.sub });
                if (ret) {
                    await GetAllApplication()
                    navigator('/applications/' + data.name)
                }
            } else {
                const _data = { ...data };
                delete _data.name;
                _data.updater = auth.sub;
                const ret = await updateApp(item.name, _data);
                if (ret) {
                    await GetApplication(item.name)
                    setEdit(false);
                }
            }
        }
        if (uploads.length > 0) {
            Promise.all(uploads.map(f => f())).then(async () => {
                await next();
                setUploadHooks({});
            }).catch((err) => {
                console.log(err);
                toast('Fail Upload File', { type: 'error' });
            })
        } else {
            await next()
        }

    }, [GetAllApplication, GetApplication, create, createApp, data, item?.name, navigator, updateApp, uploadHooks, auth?.sub]);

    const handlerClickEdit = useCallback((bool) =>{
        genData();
        setEdit(bool);
    }, [genData]);

    return <>
        <div className="app-content">
            <div className="card">
                <RAYTabs tabs={tabs} tab={tab} setTab={(t) => navigator("/applications/" + appName + "/" + t?.code) } hideLine={true} itemCss="px-2 py-4" css="px-4 border-top-1" />
                <div className="card-body border-top">
                    {tab.code === TABS.BASIC && <AppSettingBasic data={data} setData={setData} create={create} edit={edit} item={item} />}
                    {tab.code === TABS.TEAMS && <AppSettingTeams data={data} setData={setData} create={create} edit={edit} item={item} setUploadHooks={setUploadHooks} />}
                    {tab.code === TABS.ATTR && <AppSettingAttrs data={data} setData={setData} create={create} edit={edit} item={item} />}
                    {tab.code === TABS.UI && <AppSettingUI data={data} setData={setData} create={create} edit={edit} item={item} setUploadHooks={setUploadHooks} />}
                    {tab.code === TABS.MANUALS && <AppSettingManualsV2 app={item} />}
                    {tab.code === TABS.TERMS && <AppSettingTerms data={data} setData={setData} create={create} edit={edit} item={item} />}
                    {tab.code === TABS.BANNER && <AppSettingBanner data={data} setData={setData} create={create} edit={edit} item={item} />}
                    {tab.code === TABS.ACTIVITYLOG && <AppSettingActivityLog data={data} setData={setData} create={create} edit={edit} item={item} />}
                    {tab.code === TABS.REPORT && <AppSettingAnal data={data} setData={setData} create={create} edit={edit} item={item} />}
                    {tab.code === TABS.ERROR && <AppSettingErrors data={data} setData={setData} create={create} edit={edit} item={item} />}
                    {tab.code === TABS.CONFIG && <AppSettingConfig data={data} setData={setData} create={create} edit={edit} item={item} />}
                    {tab.code === TABS.LANGUAGE && <AppSettingLang data={data} setData={setData} create={create} edit={edit} item={item} />}
                </div>
                {!noneFooterTabs.includes(tab.code) && <>
                    <div className="card-footer d-flex gap-1 justify-content-end border-top pt-4">
                        {create && <button type="reset" className="btn btn-light btn-active-light-primary" onClick={() => navigator('/applications')}>Back</button>}
                        {(!create && edit) && <button type="reset" className="btn btn-light btn-active-light-primary" onClick={() => handlerClickEdit(false)}>Cancel</button>}
                        {!edit && <button type="reset" className="btn btn-light-danger" onClick={deleteHandler}>Delete</button>}
                        {!edit && <button type="submit" className="btn btn-primary" onClick={() => handlerClickEdit(true)}>Edit</button>}
                        {edit && <button type="submit" className="btn btn-primary" onClick={updateHandler}>{create ? 'Create' : 'Update'}</button>}
                    </div>
                </>}
            </div>
        </div>
    </>
}



const mapState = (state) => {
    const isLogin = state.AuthReducer.isLogin;
    const auth = state.AuthReducer.user;
    const apps = state.ApplicationReducer.apps;
    const appVersions = state.ApplicationVersionReducer.appVersions;
    return { isLogin, auth, apps, appVersions };
};

const mapDispatch = (dispatch) => ({
    GetAllApplication: () => dispatch(ApplicationAction.GetAllApplication()),
    GetApplication: (appName) => dispatch(ApplicationAction.GetApplication(appName)),
    GetApplicationVersions: (appName) => dispatch(ApplicationVersionAction.GetApplicationVersions(appName)),
})

export default connect(mapState, mapDispatch)(ApplicationView);
