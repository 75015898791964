import { useMemo } from "react";
import { connect } from "react-redux";
import { UserAction } from "../../redux/actions";


const RAYUser = ({ _id, auth, users, requestedUserIds, GetUser }) => {
    const user = useMemo(() => {
        if (_id === auth?._id) {
            return auth;
        }
        const _user = users?.find(x => x.sub === _id);
        if (_user?.sub) {
            return _user;
        }
        const isRequested = requestedUserIds?.find(x => x === _id);
        if (isRequested) {
            return { _id, name: "..." };
        } else {
            GetUser(_id);
        }
        return { _id, name: "..." };
    }, [_id, auth, users, requestedUserIds]);

    return user.name;
};

const mapState = (state) => {
    const auth = state.AuthReducer.user;
    const users = state.UserReducer.items;
    const requestedUserIds = state.UserReducer.requestedUserIds;
    return { auth, users, requestedUserIds };
};

const mapDispatch = (dispatch) => ({
    GetUser: (_id) => dispatch(UserAction.Get(_id)),
});

export default connect(mapState, mapDispatch)(RAYUser);
