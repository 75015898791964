import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Select from "react-select";
import RAYFormRaw from '../../../components/common/RAYFormRaw';
import LoginProd from '../../../pages/login/LoginProd';
import ApplicationVersionDetailView from '../../../components/applicationVersion/ApplicationVersionDetailView';
import ApplicationVersionLangView from '../../../components/applicationVersion/ApplicationVersionLangView';
import { RAYIconAngleBracket, RAYIconCircle } from '../../../components/common/RAYIcon';
import RAYSelect from '../../../components/common/RAYSelect';
import ApplicationToolbar from '../../../components/toolbars/ApplicationToolbar';
import { LANGUAGE } from '../../../constants';
import PageInOutHook from '../../../hooks/PageInOutHook';
import { getFileSize } from '../../../libs/fileSaver';
import { ApplicationVersionAction, ApplicationAction, AliasAction } from '../../../redux/actions';
import RAYButton from "../../../components/common/RAYButton";
import { history } from "../../../history";
import EmailAliasImportModal from '../../../components/alias/EmailAliasImportModal';
import _ from 'lodash';

const ApplicationVersionView = ({ apps, appVersions, isLoginProd, CheckEmailAlias, emailAlias }) => {
    const { appName, version } = useParams();
    const [setToolbar] = PageInOutHook();
    const [app, setApp] = useState({});
    const [info, setInfo] = useState(null);
    const [langs, setLangs] = useState([]);
    const [authusers, setAuthusers] = useState([]);
    const [versions, setVersions] = useState([]);
    const [lastVersion, setLastVersion] = useState('');
    const [lang, setLang] = useState('');
    const [edit, setEdit] = useState(false);
    const [aemails, setAEmails] = useState("");
    const [createLang, setCreateLang] = useState(null);
    const [uploadHooks, setUploadHooks] = useState({});
    const [prodLoginModal, setProdLoginModal] = useState(false);

    const navigator = useNavigate();

    useEffect(() => {
        CheckEmailAlias();
    }, []);

    useEffect(() => {
        appName && apps.length && setApp(apps.find(x => x.name === appName));
    }, [apps, appName]);

    useEffect(() => {
        if (app?.name) {
            setToolbar(<ApplicationToolbar
                appName={app.name}
                title={<>{app?.title} <small>{app?.name}</small></>}
                suffixDom={<div>
                    <Link to={`/applications/${app.name}`} className="btn btn-dark">App Home</Link>
                </div>}
            />);
        }
    }, [app, setToolbar]);

    const getVersion = useCallback(async () => {
        if (!appName || !version) { return; }
        const data = await ApplicationVersionAction.GetApplicationVersion(appName, version)
        if (data) {
            setAuthusers(data?.version?.authusers || []);
            setInfo(data.version);
            setLangs(data.langs);
        }
    }, [appName, version]);

    useEffect(() => {
        getVersion();
        return () => {
            setInfo(null);
            setLangs([]);
        }
    }, [getVersion]);

    useEffect(() => {
        appName && setVersions(appVersions[appName] || []);
    }, [appName, appVersions])

    useEffect(() => {
        let _lastVersion = '';
        for (const version of versions) {
            if (_lastVersion) {
                break;
            }
            if (version.is_active) {
                _lastVersion = version.version;
            }
        }
        setLastVersion(_lastVersion);
    }, [versions]);

    const updateAuthUsers = async (items) => {
        const _data = { appName: appName, data: { version : version, data : { authusers: items } } };
        const ret = await ApplicationVersionAction.UpdateApplicationVersion(_data);
        if (ret?.version) {
            setAuthusers(items);
            toast('Success Update Auth-users', { type: 'success' });
            return true;
        } else {
            toast('Fail Update Auth-users', { type: 'error' });
            return false;
        }
    }

    const callbackProduction = useCallback( async () => {
        if(!isLoginProd){
            setProdLoginModal(true);
        }else{
            await verionToProd();
        }
    }, [isLoginProd]);

    const callbackLoginProd = async () => {
        setProdLoginModal(false);
        //await verionToProd();
    }

    const verionToProd = async () => {
        if(confirm("운영 서버로 해당 버전을 전달하겠습니까?")){
            const devVersions = await ApplicationAction.GetAppVersion( appName, version, true);
            const prdVersions = await ApplicationAction.GetAppVersion( appName, version, false);
            if(prdVersions.length > 0){
                alert("Already Existed");
                return;
            }
            const devVersionsItems = await ApplicationAction.GetAppVersion( appName, version + ":", true, false);
            const inputs = [...devVersions, ...devVersionsItems];
            await ApplicationAction.DeployAppVersion(inputs);
            alert("Deploy production service done!");
        }
    }

    const versionUpdateHandler = useCallback(async (data) => {
        const uploadFields = Object.keys(uploadHooks);
        delete data.file;
        const uploads = [];
        for (const uploadField of uploadFields) {
            const upload = uploadHooks[uploadField];
            const fnc = async () => {
                const upRet = await upload();
                if (upRet.name.startsWith("manuals_")) {
                    const manualLang = upRet.name.replace("manuals_", "");
                    let _manualDocs =  data.manualDocs || [];
                    _manualDocs = _manualDocs.filter(x => x.manualLang !== manualLang);
                    data.manualDocs = [..._manualDocs, { manualLang, ...upRet.data }];
                    delete data[upRet.name];
                } else {
                    data[upRet.name] = upRet.data;
                    data[upRet.name + '_name'] = upRet.data.file_name;
                }
            };
            uploads.push(fnc);
        }

        const next = async () => {
            const _data = { appName: appName, data: { version: version, data } };
            const ret = await ApplicationVersionAction.UpdateApplicationVersion(_data);
            if (ret?.version) {
                await getVersion();
                toast('Success Update Version', { type: 'success' });
                return true;
            } else {
                toast('Fail Create Version.', { type: 'error' });
                return false;
            }
        }

        if (uploads.length > 0) {
            Promise.all(uploads.map(f => f())).then(async () => {
                await next();
                setUploadHooks({});
            }).catch(() => {
                toast('Fail Upload File', { type: 'error' });
            })
        } else {
            await next()
        }
    }, [getVersion, appName, version, uploadHooks])


    return <>
        {info && <div className="row">
            <div className="col-3">
                <div className="card">
                    <div className="card-header">
                        <h5 className="mb-0">
                            v{info.version} {(lastVersion === info.version) && <span className="badge bg-primary">Latest</span>}
                        </h5>
                    </div>
                    {/*<div className="card-header border-top">
                        {(lang !== '' || edit) && <>
                            <div>
                                {lastVersion && <>Latest version<span>|</span></>}
                                {getFileSize(info.file_size)}
                            </div>
                            <div>{moment(info.created).format('LLL')}</div>
                        </>}
                        {lang !== '' && <Link to="#" className="btn btn-primary" onClick={() => setLang('')}>View version detail</Link>}
                    </div>*/}
                    <div className="card-body border-top pt-4">
                        <h5>Release note</h5>
                        <RAYSelect
                            items={LANGUAGE.filter(x => langs.every(y => y.lang !== x.code)).map(x => ({ ...x, value: x.code }))}
                            onChange={console.log}
                            placeholder='Add language'
                            callbackOnChange={val => {
                                setLang('');
                                setCreateLang(val);
                            }}
                            value={createLang}
                            style={{ marginBottom: '1.5rem' }}
                        />
                        <div>
                            {langs.length > 0 && langs.map((x, idx) => <div className="d-flex justify-content-between" key={idx}>
                                <span className={"menu-link" + (lang === x.lang ? ' active ' : '')} onClick={() => {
                                    setCreateLang(null);
                                    setLang(x.lang)
                                }}>
                                    <span className="menu-icon">
                                        <span className="svg-icon">
                                            <RAYIconCircle size="24" />
                                        </span>
                                    </span>
                                    <span className="menu-title">{LANGUAGE.find(y => y.code === x.lang)?.label || x.lang}</span>
                                    {x.is_active && <span className="badge bg-primary">Published</span>}
                                    {!x.is_active && <span className="badge bg-secondary">Draft</span>}
                                </span>
                                <span className="btn-icon text-end">
                                    <RAYIconAngleBracket size="24" />
                                </span>
                            </div>)}
                            {(createLang && createLang.code) && <>
                                <div className="menu-item">
                                    <span className={"menu-link active"}>
                                        <span className="menu-icon">
                                            <span className="svg-icon">
                                                <RAYIconCircle size="24" />
                                            </span>
                                        </span>
                                        <span className="menu-title">{createLang.label}</span>
                                        <span className="badge bg-primary">In Progress</span>
                                    </span>
                                </div>
                            </>}
                            {(langs.length === 0 && !createLang) && <>
                                Not find Release note.
                            </>}
                        </div>
                    </div>
                    <div className="card-body border-top pt-4">
                        <h5>Authorized Users</h5>
                        <textarea 
                            name="version"
                            className="form-control"
                            placeholder="User email"
                            value={aemails}
                            onChange={(e) => setAEmails(e.target.value) }
                            rows={7} />
                        <div className="pt-2 btn-block">
                            <RAYButton
                                css="w-100"
                                label="Add authorized user(s)"
                                color="secondary"
                                onClick={(e) => {
                                    var n = aemails;
                                    var m = n.split('\n');
                                    let _emails = [...authusers, ...m.filter(x => x?.trim())]
                                    _emails = _emails.map(x => x?.trim());
                                    _emails = [...(new Set(_emails))];
                                    _emails = _emails.sort();
                                    const originEmails = (authusers || []).sort();
                                    if (!_.isEqual(originEmails, _emails)) {
                                        updateAuthUsers(_emails);
                                        
                                    }
                                    setAEmails("");
                                }}
                            />
                            {emailAlias?.filter(x => x.activated && !x.isDel)?.length > 0 && <div className='mt-2'>
                                <EmailAliasImportModal  
                                    slotButton={<RAYButton
                                        css="w-100"
                                        label="Import e-mail group"
                                        color="secondary"
                                    />}
                                    callbackSelect={aliasItem => {
                                        if (Array.isArray(aliasItem?.emails)) {
                                            let _emails = [...(authusers || []), ...aliasItem?.emails];
                                            _emails = [...(new Set(_emails))].sort();
                                            const originEmails = (authusers || []).sort();
                                            if (!_.isEqual(originEmails, _emails)) {
                                                updateAuthUsers(_emails);
                                            }
                                        }
                                    }}
                                />
                            </div>}
                        </div>
                        <div className="">
                            {authusers?.map((x, idx) => <div className="d-flex justify-content-between align-items-center" key={idx}>
                                <div className="text-break">{x}</div>
                                <button className="btn btn-sm btn-danger py-2 px-3 my-2 ms-2" 
                                    onClick={() => updateAuthUsers(authusers.filter(n => n != x))}>X</button>
                            </div>)}
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-9">
                {lang === '' && !createLang && <ApplicationVersionDetailView
                    item={info}
                    callbackCancel={() => navigator('/applications/' + appName)}
                    callbackUpdate={versionUpdateHandler}
                    callbackProduction={() => callbackProduction()}
                    callbackUploadHooks={val => setUploadHooks(val)}
                    callbackEditChange={(bool) => bool && setEdit(bool)}
                />}
                {(lang || createLang) && <ApplicationVersionLangView
                    version={info}
                    item={createLang ? null : langs.find(x => x.lang === lang)}
                    lang={createLang}
                    callbackCancel={() => {
                        setLang('');
                        setCreateLang(null);
                    }}
                    callbackCreateSuccess={() => {
                        setCreateLang(null);
                        getVersion();
                    }}
                    callbackDeleteLang={() => {
                        setLang('');
                        getVersion();
                    }}
                    callbackUpdateSuccess={() => {
                        getVersion();
                    }}
                />}
            </div>
        </div>}
        <LoginProd initmodal={prodLoginModal} callbackLoginProd={callbackLoginProd} clodeModal={() => setProdLoginModal(false)} />
    </>
}

const mapState = (state) => {
    const isLogin = state.AuthReducer.isLogin;
    const isLoginProd = state.AuthReducer.isLoginProd;
    const apps = state.ApplicationReducer.apps;
    const appVersions = state.ApplicationVersionReducer.appVersions;
    const emailAlias = state.AliasReducer.emailAlias;
    return { isLogin, isLoginProd, apps, appVersions, emailAlias };
};

const mapDispatch = (dispatch) => ({
    CheckEmailAlias: () => dispatch(AliasAction.CheckEmailAlias()),
    GetApplicationVersions: (appName) => dispatch(ApplicationVersionAction.GetApplicationVersions(appName))
})

export default connect(mapState, mapDispatch)(ApplicationVersionView);
