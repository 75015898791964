import { MdChevronLeft, MdChevronRight, MdFirstPage, MdLastPage } from 'react-icons/md';
import { usePagination, useSortBy, useTable } from 'react-table';
import RAYSelect from './RAYSelect';

const RAYTable = ({ tableHeaders, tableData, usePaginate = false, initPageIndex = 0, initPageSize = 10, useSort = false, style={}, gotoPage, nextPage, autoResetPage = true }) => {
    const tableInstance = useTable({
        columns: tableHeaders,
        data: tableData,
        autoResetPage: autoResetPage,
        initialState: { pageIndex: initPageIndex, pageSize: initPageSize },
    }, useSortBy, usePagination);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage: gotoPageSelf,
        nextPage: nextPageSelf,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = tableInstance;

    const pushGotoPage = (pageNumber) => {
        if (gotoPage) {
            gotoPage(pageNumber);
        } else if (gotoPageSelf) {
            gotoPageSelf(pageNumber);
        }
    };
    const pushNextPage = () => {
        if (nextPage) {
            nextPage();
        } else if (nextPageSelf) {
            nextPageSelf();
        }
    };

    return <>
        <table {...getTableProps()} className="table bg-white" style={style}>
            <thead style={{ userSelect: 'none' }}>
                {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => {
                            return <th {...column.getHeaderProps(useSort && !column?.noneSort && column.getSortByToggleProps())}>
                                {column.render('Header')}
                                <span>
                                    {column.isSorted
                                        ? column.isSortedDesc
                                            ? ' 🔽'
                                            : ' 🔼'
                                        : ''}
                                </span>
                            </th>
                        })}
                    </tr>
                ))}
            </thead>
            <tbody {...getTableBodyProps()}>
                {page.map(row => {
                    prepareRow(row)
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map(cell => {
                                return (
                                    <td {...cell.getCellProps()}>
                                        {cell.render('Cell')}
                                    </td>
                                )
                            })}
                        </tr>
                    )
                })}
            </tbody>
        </table>
        <div className="py-2"></div>
        {usePaginate && <div className="pagination">
            <MdFirstPage className={(canPreviousPage ? 'raycur' : 'text-muted disabled')} size={36} onClick={() => pushGotoPage(0)} />
            <MdChevronLeft className={(canPreviousPage ? 'raycur' : 'text-muted disabled')} size={36} onClick={() => previousPage()} />
            <RAYSelect 
                items={pageOptions.map((x) => ({ label: `${x + 1} / ${pageOptions.length}`, value: x }))}
                value={{ label: `${pageIndex + 1} / ${pageOptions.length}`, value: pageIndex }}
                callbackOnChange={obj => pushGotoPage(obj.value)}
                menuPlacement="top"
                clearable={false}
            />
            <MdChevronRight className={(canNextPage ? 'raycur' : 'text-muted disabled')} size={36} onClick={() => pushNextPage()} />
            <MdLastPage className={(canNextPage ? 'raycur' : 'text-muted disabled')} size={36} onClick={() => pushGotoPage(pageCount - 1)}  />
            <RAYSelect 
                items={[5, 10, 20, 30, 40, 50].map(x => ({ label: `${x} per page`, value: x }))}
                value={{ label: `${pageSize} per page`, value: pageSize }}
                callbackOnChange={obj => setPageSize(Number(obj.value))}
                menuPlacement="top"
                clearable={false}
                css="ms-2"
            />
        </div>}
    </>
};

export default RAYTable;
