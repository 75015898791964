import { useCallback } from "react";
import RAYFile from "../common/RAYFile";
import RAYFormRaw from "../common/RAYFormRaw";
import RAYFormRawInline from "../common/RAYFormRawInline";
import RAYTextField from "../common/RAYTextField";
import RAYToggle from "../common/RAYToggle";
import AppSettingUIServiceProjectForm from "./AppSettingUIServiceProjectForm";

const AppSettingUI = ({ edit = false, create = false, item, data, setData, setUploadHooks }) => {

    const handlerSetUploadHooks = useCallback((obj) => {
        setUploadHooks && setUploadHooks(prev => ({
            ...prev, [obj.name]: {
                name: obj.name,
                fieldName: obj.fieldName,
                inArr: obj.inArr,
                inArrId: obj.inArrId,
                fnc: obj.upload
            }
        }));
    }, [setUploadHooks]);

    return <>
        <div>
            <RAYFormRawInline title="RAYTeams Apps" labelCss="" labelCol={3}>
                {!edit && <span>{item?.rayteams_apps ? 'Include' : 'Exclude'}</span>}
                {edit && <RAYToggle
                    name="rayteams_apps"
                    value={data?.rayteams_apps}
                    trueLabel="Include"
                    falseLabel="Exclude"
                    onChange={() => setData(prev => ({ ...prev, rayteams_apps: !prev.rayteams_apps }))}
                />}
            </RAYFormRawInline>
            {!data?.rayteams_apps && <div className="text-muted">
                The settings below are valid for RAYTeams App only.
            </div>}
            {data?.rayteams_apps && <>
                <RAYFormRawInline title="Custom Setting" labelCss="" labelCol={3}>
                    <div>
                        {!edit && <span>{item?.hasSetting ? 'Has Custom Setting' : 'None Custom Setting'}</span>}
                        {edit && <RAYToggle
                            name="hasSetting"
                            value={data?.hasSetting || false}
                            trueLabel="Has Custom Setting"
                            falseLabel="None Custom Setting"
                            onChange={() => setData(prev => ({ ...prev, hasSetting: !prev.hasSetting }))}
                        />}
                        <div className="text-muted small">
                            If you have custom settings. The setup screen of RAY Teams includes iFram.<br />
                            You must have a "apps/html/{item.name}/Setting" router.
                        </div>
                    </div>
                </RAYFormRawInline>
                {data?.hasSetting && <RAYFormRawInline title="Custom Setting Height" labelCss="" labelCol={3}>
                    <div>
                        {!edit && <span>{item?.settingHeight ? (item.settingHeight + "px") : 'None Height'}</span>}
                        {edit && <RAYTextField
                            name="setting_height"
                            type="number"
                            value={data.settingHeight}
                            placeholder="insert integer"
                            onChange={(event) => setData(prev => ({ ...prev, settingHeight: (event.target.value || '').trim() }))}
                        />}
                        <div className="text-muted small">
                            The height of the setting element.
                        </div>
                    </div>
                </RAYFormRawInline>}
                <RAYFormRawInline title="Service - Project" labelCss="" labelCol={3}>
                    <div className="w-100">
                        {!edit && <span>{item?.serviceProject ? 'service' : 'none'}</span>}
                        {edit && <RAYToggle
                            name="serviceProject"
                            value={data?.serviceProject}
                            trueLabel="service"
                            falseLabel="none"
                            onChange={() => setData(prev => ({ ...prev, serviceProject: !prev.serviceProject }))}
                        />}
                        <div className="text-muted small">
                            <ul>
                                This option requires an API for the app
                                <li>.../projectHook -&gt; Called in the first synchronization project.</li>
                                <li>.../checkUseable -&gt; Called to check which apps are available.</li>
                            </ul>
                        </div>
                    </div>
                </RAYFormRawInline>
                {data?.serviceProject && <div className="mt-4"> <RAYFormRawInline title="Service - Project Assets" labelCss="" labelCol={3}>
                    <div className="w-100">
                        <div className="p-5" style={{ background: "#1f2239" }}>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className="fw-bolder text-white">{item?.serviceProjectAssets?.serviceName || <i className="text-muted">{item?.name}</i>}</div>
                                <div className="text-muted">{item?.title || item?.name} Asset Preview</div>
                            </div>
                            <div>
                                <span className="me-2 text-muted">Logo</span>
                                {item?.serviceProjectAssets?.logo?.direct_link && <span className="ms-2">
                                    <img src={item?.serviceProjectAssets?.logo?.direct_link} alt="button icon" width="146" style={{ background: "#1f2239", padding: 10 }} />
                                </span>}
                                {!item?.serviceProjectAssets?.logo?.direct_link && <span className="ms-2">
                                    None
                                </span>}
                            </div>
                            <div>
                                <span className="me-2 text-muted">Button</span>
                                <div
                                    className="d-inline-flex align-items-center justify-content-center gap-2 text-white px-4 py-2"
                                    style={{
                                        background: item?.serviceProjectAssets?.buttonBGColor || "#dddddd",
                                        borderRadius: 4
                                    }}
                                >
                                    {item?.serviceProjectAssets?.buttonIcon?.direct_link && <span className="ms-2">
                                        <img src={item?.serviceProjectAssets?.buttonIcon?.direct_link} alt="button icon" style={{ width: 40 }} />
                                    </span>}
                                    <span className="fw-bolder">{item?.serviceProjectAssets?.syncBtnText || <i className="text-muted">{item?.name}</i>}</span>
                                </div>
                                <div className="d-flex gap-2">
                                    <div className="text-muted">Project Detail Button Action</div>
                                    <div className="text-white">{item?.serviceProjectAssets?.projectDetailAction || "-"}</div>
                                </div>
                            </div>
                        </div>
                        {edit && <div className="d-flex flex-column gap-3 mt-4">
                            <div>
                                <span>Company Name</span>
                                <RAYTextField
                                    value={data?.serviceProjectAssets?.companyName || ""}
                                    placeholder="Insert Company Name"
                                    onChange={e => setData(prev => {
                                        const _tmp = { ...prev };
                                        _tmp.serviceProjectAssets.companyName = e.target.value;
                                        return _tmp;
                                    })}
                                />
                            </div>
                            <div>
                                <span>Service Logo (W)</span>
                                <RAYFile
                                    item={item?.serviceProjectAssets?.logo || null}
                                    edit={true}
                                    hideFileName={true}
                                    fieldName={'serviceProjectAssets.logo'}
                                    uploadFileName="serviceProjectLogo"
                                    keyPrefix={`rayrnd/applications/${item?.name}/assets/`}
                                    callbackUploadHook={handlerSetUploadHooks}
                                />
                            </div>
                            <div>
                                <span>Display Service Name</span>
                                <RAYTextField
                                    value={data?.serviceProjectAssets?.serviceName || ""}
                                    placeholder="Insert Name"
                                    onChange={e => setData(prev => {
                                        const _tmp = { ...prev };
                                        _tmp.serviceProjectAssets.serviceName = e.target.value;
                                        return _tmp;
                                    })}
                                />
                            </div>
                            <div>
                                <span>Button Icon</span>
                                <RAYFile
                                    item={item?.serviceProjectAssets?.buttonIcon || null}
                                    edit={true}
                                    hideFileName={true}
                                    fieldName={'serviceProjectAssets.buttonIcon'}
                                    uploadFileName="serviceProjectButton"
                                    keyPrefix={`rayrnd/applications/${item?.name}/assets/`}
                                    callbackUploadHook={handlerSetUploadHooks}
                                />
                            </div>
                            <div>
                                <span>Button Text</span>
                                <RAYTextField
                                    value={data?.serviceProjectAssets?.syncBtnText || ""}
                                    placeholder="Insert Button text"
                                    onChange={e => setData(prev => {
                                        const _tmp = { ...prev };
                                        _tmp.serviceProjectAssets.syncBtnText = e.target.value;
                                        return _tmp;
                                    })}
                                />
                            </div>
                            <div>
                                <span>Button BG Color</span>
                                <RAYTextField
                                    type="color"
                                    value={data?.serviceProjectAssets?.buttonBGColor || ""}
                                    placeholder="Insert Button Color"
                                    onChange={e => setData(prev => {
                                        const _tmp = { ...prev };
                                        _tmp.serviceProjectAssets.buttonBGColor = e.target.value;
                                        return _tmp;
                                    })}
                                    style={{ height: 40 }}
                                />
                            </div>
                            <div>
                                <span>Project Detail Button Action</span>
                                <RAYTextField
                                    value={data?.serviceProjectAssets?.projectDetailAction || ""}
                                    placeholder="Insert Web URL"
                                    onChange={e => setData(prev => {
                                        const _tmp = { ...prev };
                                        _tmp.serviceProjectAssets.projectDetailAction = e.target.value;
                                        return _tmp;
                                    })}
                                />
                            </div>
                        </div>}
                    </div>
                </RAYFormRawInline></div>}
                {data?.serviceProject && <div className="mt-4">
                    <RAYFormRawInline title="Service - Project Form" labelCss="" labelCol={3}>
                        <AppSettingUIServiceProjectForm
                            edit={edit}
                            create={create}
                            item={item}
                            data={data}
                            setData={setData}
                            setUploadHooks={setUploadHooks}
                        />
                    </RAYFormRawInline>
                    <RAYFormRawInline title="Service - Project Config" labelCss="" labelCol={3}>
                        <div>
                            <div>
                                <span>Send Message to lab user's when creation case</span>
                                <div>
                                    <RAYToggle
                                        value={data?.serviceProjectConfig?.useCreationMessage || false}
                                        onChange={e => setData(prev => {
                                            const _tmp = { ...prev };
                                            _tmp.serviceProjectConfig.useCreationMessage = e.target.checked;
                                            return _tmp;
                                        })}
                                        disabled={!edit}
                                    />
                                </div>
                            </div>
                            <div>
                                <span>Allow memo when creation case</span>
                                <div>
                                    <RAYToggle
                                        value={data?.serviceProjectConfig?.allowMemo || false}
                                        onChange={e => setData(prev => {
                                            const _tmp = { ...prev };
                                            _tmp.serviceProjectConfig.allowMemo = e.target.checked;
                                            return _tmp;
                                        })}
                                        disabled={!edit}
                                    />
                                </div>
                            </div>
                            <div>
                                <span>Direct upload when imported same case</span>
                                <div>
                                    <RAYToggle
                                        value={data?.serviceProjectConfig?.forceUploadOnSameCase || false}
                                        onChange={e => setData(prev => {
                                            const _tmp = { ...prev };
                                            _tmp.serviceProjectConfig.forceUploadOnSameCase = e.target.checked;
                                            return _tmp;
                                        })}
                                        disabled={!edit}
                                    />
                                </div>
                            </div>
                        </div>
                    </RAYFormRawInline>
                </div>}
            </>}
        </div>
    </>
}

export default AppSettingUI;
