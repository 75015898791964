import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { history } from '../history';
import { TOPMENU } from '../constants/topmenu';
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';

const TopAside = ({ isLogin, auth }) => {

    const dispath = useDispatch();
    const [t] = useTranslation(['translation']);
    const navigator = useNavigate();
    const hasAccountRole = useMemo(() => auth?.is_account_manager || auth?.is_super_admin, [auth]);

    // useEffect(() => {
    //     console.log("auth", auth);
    // }, [auth])

    return <aside id="layout-menu" className="layout-menu-horizontal menu-horizontal menu bg-menu-theme flex-grow-0">
        <div className="container-fluid d-flex h-100">
            <a href="#" className="menu-horizontal-prev d-none"></a>
            <div className="menu-horizontal-wrapper">
                <ul className="menu-inner py-1">
                    {isLogin && <>
                        {/* <li className="menu-item">
                            <Link to={"/dashboard"} className="menu-link">
                                <i className={"menu-icon ti ti-layout-dashboard"}></i>
                                <div>Dashboard</div>
                            </Link>
                        </li> */}
                        <li className="menu-item">
                            <Link to={"/applications"} className="menu-link">
                                <i className={"menu-icon ti ti-box"}></i>
                                <div>My Applications</div>
                            </Link>
                        </li>
                    </>}
                    {TOPMENU.map((x, idx) => <li className="menu-item" key={"m-" + idx}>
                        <Link className="menu-link" to={x.path}>
                            <i className={"menu-icon ti ti-" + x.icon}></i>
                            <div>{x.label}</div>
                        </Link>
                    </li>)}
                    {hasAccountRole && <>
                        <li className="menu-item">
                            <Link className="menu-link" to={"/users"}>
                                <i className={"menu-icon ti ti-user"}></i>
                                <div>Member</div>
                            </Link>
                        </li>
                        <li className="menu-item">
                            <Link className="menu-link" to={"/email-alias"}>
                                <i className={"menu-icon ti ti-users"}></i>
                                <div>Email Group</div>
                            </Link>
                        </li>
                    </>}
                </ul>
            </div>
            <a href="#" className="menu-horizontal-next d-none"></a>
        </div>
    </aside>
}

const mapState = (state) => {
    const isLogin = state.AuthReducer.isLogin;
    const auth = state.AuthReducer.user;
    return { isLogin, auth };
};

const mapDispatch = (dispatch) => ({

});

export default connect(mapState, mapDispatch)(TopAside);
