import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Badge } from "react-bootstrap";
import { connect } from "react-redux";
import EmailAliasEditModal from "../../components/alias/EmailAliasEditModal";
import { RAYIconSetting } from "../../components/common/RAYIcon";
import RAYTable from "../../components/common/RAYTable";
import RAYTextField from "../../components/common/RAYTextField";
import RAYUser from "../../components/common/RAYUser";
import PageInOutHook from "../../hooks/PageInOutHook";
import { AliasAction } from "../../redux/actions";


const EmailAlias = ({ emailAlias, CheckEmailAlias, callType = "page" }) => {
    const [q, setQ] = useState("");
    const [showDeleteItems, setShowDeleteItems] = useState(false);
    const [setToolbar] = PageInOutHook();
    useEffect(() => {
        callType === "page" && setToolbar(<div>
            <h1>Email Group</h1>
        </div>)
    }, [setToolbar, callType]);

    const list = useMemo(() => {
        return emailAlias.filter(x => {
            if (!showDeleteItems && x.isDel) {
                return false;
            }
            if (q) {
                const checkValues = [x.alias, ...(x.emails || [])];
                const hasKeyword = checkValues.some(x => x?.toLowerCase()?.startsWith(q?.toLowerCase()));
                return hasKeyword;
            }
            return true;
        }).sort((a, b) => a.alias < b.alias ? -1 : 1)
    }, [emailAlias, q, showDeleteItems]);

    const tableHeaders = useMemo(() => {
        return [
            { Header: 'Alias', accessor: 'alias' },
            { Header: 'Activated', accessor: 'activated', noneSort: true },
            { Header: 'E-MAILS', accessor: 'emails' },
            { Header: 'Creator', accessor: 'creator' },
            { Header: 'CREATED', accessor: 'created' },
            { Header: '', accessor: 'actions', noneSort: true },
        ];
    }, []);

    const tableList = useMemo(() => {
        const _list = list.map(x => {
            const _item = _.cloneDeep(x);
            _item.activated = <Badge bg={x.activated ? "primary" : "secondary"}>{x.activated ? "Activated" : "Deactivated"}</Badge>;
            _item.emails = (!x.emails || x.emails?.length === 0) ? "None" : `Total ${x.emails.length}`;
            _item.creator = <RAYUser _id={x.creator} />
            _item.created = moment(x.created).format("LL");
            _item.actions = <EmailAliasEditModal
                item={x} key={`${x.name}-${x.created || x.updated}`}
                slotButton={<RAYIconSetting className="text-primary cursor-pointer" />}
            />;
            _item.original = x;
            return _item;
        });
        return _list;
    }, [list]);

    useEffect(() => {
        CheckEmailAlias();
    }, []);

    return <>
        <div className="pt-4">
            <div>
                <div className="d-flex align-items-center gap-2 mb-2">
                    {emailAlias?.length > 0 && <>
                        <RAYTextField
                            value={q}
                            onChange={e => setQ(e.target?.value || "")}
                            placeholder={"Search alias name or email address"}
                        />
                    </>}
                    <EmailAliasEditModal />
                </div>
            </div>
            <RAYTable
                tableHeaders={tableHeaders}
                tableData={tableList}
                useSort={true}
                usePaginate={true}
                initPageSize={10}
                autoResetPage={false}
            />
        </div>
    </>
};

const mapState = (state) => {
    const emailAlias = state.AliasReducer.emailAlias;
    return { emailAlias };
};

const mapDispatch = (dispatch) => ({
    CheckEmailAlias: () => dispatch(AliasAction.CheckEmailAlias()),
});

export default connect(mapState, mapDispatch)(EmailAlias);
