import { ArcElement, BarElement, CategoryScale, Chart as ChartJS, Colors, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Bar } from 'react-chartjs-2';
import RAYSelect from "../../components/common/RAYSelect";
import { CommonAction } from "../../redux/actions";
import saveAs from 'file-saver';
import RAYButton from '../common/RAYButton';

const rangeList = [
    { value: "31d", label: "month" },
    { value: "6m", label: "6 months" },
    { value: "12m", label: "1 year" },
    { value: "36m", label: "3 years for monthly" },
    { value: "3y", label: "3 years" },
];
const calcDataSet = {
    d: { unit: "days", format: "YYYY-MM-DD", labelFormat: "MM-DD" },
    m: { unit: "months", format: "YYYY-MM" },
    y: { unit: "years", format: "YYYY" },
};
const AppClickDownloadChart = ({ item }) => {
    const [dataChart, setDataChart] = useState({});
    const [rows, setRows] = useState([]);
    const [selectedRange, setSelectedRange] = useState(rangeList[0]);

    const options = useMemo(() => {
        return {
            plugins: {
                title: { display: true, text: `Click Download(Last ${selectedRange.label})` },
            },
            maintainAspectRatio: false,
            responsive: true,
            interaction: {
                intersect: false,
            },
            scales: {
                x: { stacked: true, },
                y: { stacked: true, },
            },
        }
    }, [selectedRange]);

    ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend, Colors);

    const getClickData = async () => {
        const minDateTS = moment().subtract(3, "years").valueOf();
        const payload = {
            type: "query",
            region: "ap-northeast-2",
            params: {
                TableName: "rayrnd-applications",
                KeyConditionExpression: "#name = :name and begins_with(#type, :type)",
                FilterExpression: "#updated > :updated",
                ExpressionAttributeNames: {
                    "#name": "name",
                    "#type": "type",
                    "#updated": "updated",
                },
                ExpressionAttributeValues: {
                    ":name": item.name,
                    ":type": "stat:",
                    ":updated": minDateTS,
                },
            },
        };
        let list = await CommonAction.CallDB(payload)
        list = list.filter(x => x.type.includes(":click"));
        if (Array.isArray(list)) {
            setRows(list);
        } else {
            setRows([]);
        }
    };

    useEffect(() => {
        getClickData();
    }, []);

    const chartLabels = useMemo(() => {
        const currentTarget = selectedRange?.value || "31d";
        const calcData = calcDataSet[currentTarget.at(-1)];
        const targetNumber = Number(currentTarget.replace(/[a-z]/g, ""));
        let _labels = [];
        for (let i = 0; i < targetNumber; i++) {
            _labels.push(moment().add(-1 * i, calcData.unit).format(calcData.format));
        }
        _labels = _labels.reverse();
        return _labels;
    }, [selectedRange?.value]);

    useEffect(() => {
        let versionList = [...new Set(rows.map(x => x.type.indexOf(":click") > -1 && x.type.split(":")[1]))];
        versionList = versionList.sort((a, b) => a.localeCompare(b, undefined, { numeric: true }));
        let dateSet = [];
        for (const version of versionList) {
            const cntList = [];
            let total = 0;
            for (const chartLabel of chartLabels) {
                const typeString = `stat:${version}:click:${chartLabel.replaceAll("-", ":")}`;
                const cnt = rows.find(x => x.type === typeString)?.clickcnt ?? 0;
                cntList.push(cnt);
                total += cnt;
            }
            if (total > 0) {
                dateSet.push({ label: version, data: cntList });
            }
        }
        const _chartData = {
            labels: chartLabels,
            datasets: dateSet,
        };
        const calcData = calcDataSet[selectedRange?.value?.at(-1)]; 
        if (calcData.labelFormat) {
            _chartData.labels = _chartData.labels.map(x => moment(x).format("MM-DD"));
        }
        setDataChart(_chartData);
    }, [chartLabels, rows, selectedRange?.value]);

    const handlerClickExport = useCallback(async () => {
        try {
            let csvContent =  "\ufeff";
            csvContent += `"version",${dataChart.labels.join(",")}` + "\r\n"; //version," + dataChart.labels.join(",") + "\r\n";
            for (const dataSet of dataChart.datasets) {
                csvContent += `"${dataSet.label}",${dataSet.data.join(",")}` + "\r\n";
            }
            const content = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
            let fileNamePrefix = item?.title || item?.name;
            fileNamePrefix = `${fileNamePrefix} ${selectedRange?.label}`;
            fileNamePrefix = fileNamePrefix.replaceAll(" ", "_");
            const name = `${fileNamePrefix}_click_download_count_${new Date().toISOString()}.csv`;
            saveAs(content, name);    
        } catch (error) {
            console.log("ERROR[handlerClickExport]", error?.toString());
        }
    }, [dataChart, item?.name, item?.title, selectedRange?.label]);

    return <div className="mb-2">
        <div className="d-flex justify-content-between align-items-center ">
            <h4>
                Click download-link
            </h4>
            <div className="text-muted small">
                이 집계는 RAYTeams v1.0.36.x 이상(2023.10.05)부터 집계됨
            </div>
        </div>
        <div className="d-flex align-items-center justify-content-end gap-2">
            <RAYSelect
                items={rangeList}
                value={selectedRange}
                callbackOnChange={obj => setSelectedRange(obj)}
                clearable={false}
                style={{ minWidth: 200 }}
            />
            <RAYButton 
                label="Export Excel"
                color="secondary"
                onClick={handlerClickExport}
            />
        </div>
        <div style={{ height: "400px" }}>
            {dataChart.datasets && <Bar options={options} data={dataChart} />}
        </div>
    </div>
}

export default AppClickDownloadChart;
