import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { GrLinkNext, GrLinkPrevious } from 'react-icons/gr';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ApplicationView from '../../components/application/ApplicationView';
import { RAYIconMore } from '../../components/common/RAYIcon';
import ApplicationToolbar from '../../components/toolbars/ApplicationToolbar';
import { VersionTypes } from "../../constants/versiontype";
import DownloadHook from '../../hooks/DownloadHook';
import PageInOutHook from '../../hooks/PageInOutHook';
import { getFileSize } from '../../libs/fileSaver';
import { versionSort } from '../../libs/functions';
import { ApplicationVersionAction } from '../../redux/actions';

const downloadProgressWrapStyle = {
  position: 'absolute',
  width: '100%',
  height: '100%',
  left: 0,
  top: 0,
  textAlign: 'center'
};

const downloadProgressStyle = {
  position: 'absolute',
  height: '100%',
  left: 0,
  top: 0,
  backgroundColor: '#50cd8933',
};

const VersionMoreButton = (({ item }) => {
  const [downloadFile, progress, isDownloading] = DownloadHook();

  const download = useCallback(() => {
    if (!item?.link) { return; }
    if (!item?.link || !item?.file_name) {
      toast('Wrong File', { type: 'error' });
      return;
    }
    if (isDownloading) { return; }
    downloadFile(item.link, item.file_name);
  }, [downloadFile, item, isDownloading]);

  return <div className='text-end btn-more'>
    <Dropdown align="end" autoClose="outside">
      <Dropdown.Toggle variant='default' className='btn-icon'>
        <span className="svg-icon">
          <RAYIconMore size="24" />
        </span>
      </Dropdown.Toggle>
      <Dropdown.Menu className="">
        <Dropdown.Item as='div' className="menu-item" style={{ position: 'relative' }} onClick={download}>
          <div style={downloadProgressWrapStyle}>
            <div style={{ ...downloadProgressStyle, width: progress + '%' }} />
          </div>
          <Link to="#" className="menu-link z-index-1">Download File</Link>
        </Dropdown.Item>
        <Dropdown.Item as='div' className="menu-item">
          <Link to={`/applications/${item.name}/versions/${item.version}/edit`} className="menu-link">Edit</Link>
        </Dropdown.Item>
        <Dropdown.Item as='div' className="menu-item">
          <Link to="#" className="menu-link text-danger">Delete</Link>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  </div>
});

const ApplicationHome = ({ apps, GetApplicationVersions, appVersions }) => {
  const { appName, tabcode } = useParams();
  const [app, setApp] = useState({});
  const [page, setPage] = useState(1);
  const [versions, setVersions] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [setToolbar] = PageInOutHook();

  useEffect(() => {
    appName && apps.length && setApp(apps.find(x => x.name === appName));
  }, [apps, appName]);

  useEffect(() => {
    if (!appName) { return; }
    GetApplicationVersions(appName);
  }, [appName, GetApplicationVersions]);

  useEffect(() => {
    appName && setVersions(appVersions[appName] || []);
  }, [appVersions, appName]);

  useEffect(() => {
    const _tableData = versions.map(item => {
      const version_name = <Link to={`/applications/${item.name}/versions/${item.version}`}>{'v' + item.version}</Link>;
      const file_size = item.file_size ? getFileSize(item.file_size) : '-';
      const file_name = item.file_name;
      const file_link = item.full_file?.direct_link;
      const published_at = item.published ? moment(item.published).format('LLL') : '';
      const vtype = item.vtype ? VersionTypes.find(x => x.value === item.vtype)?.label : <i>NOT DEFINED</i>;
      const created = moment(item.created).format('LLL');
      const haswebapp = item.haswebapp;
      const published = item.is_active ? <span className="badge bg-primary">Published</span> : (item.is_plan ? <span className="badge bg-success">Plan</span> : <span className="badge bg-secondary">Draft</span>);
      const actions = <VersionMoreButton item={item} />;
      const authusers = item?.authusers ?
        item.authusers.map((x, idx) => <span key={"auth-" + idx} className='badge bg-dark me-1'>{x}</span>) :
        <i>Not exists</i>;
      const full_file_size = item?.full_file?.file_size ? getFileSize(item.full_file.file_size) : '-';
      const manualDocs = item.manualDocs || [];
      return {
        file_link,
        file_name,
        version_name,
        file_size,
        vtype,
        authusers,
        haswebapp,
        full_file_size,
        created,
        published,
        actions,
        published_at,
        version: item.version,
        manualDocs,
      }
    });
    setTableData(versionSort(_tableData));
  }, [versions])

  useEffect(() => {
    if (app?.name) {
      setToolbar(<ApplicationToolbar
        appName={app.name}
        title={<>{app?.title} <small>{app?.name}</small></>}
        suffixDom={<div>
          {versions.length > 0 && <Link to={`/applications/${app.name}/cms/${versions[0].version}?lang=ko`} className="btn btn-primary me-1">CMS</Link>}
          <Link to={`/applications/${app.name}/templates`} className="btn btn-warning me-1">Homepage contents</Link>
          <Link to={`/applications/${app.name}/versions`} className="btn btn-success">Add new version</Link>
        </div>}
      />);
    }
  }, [app, setToolbar]);

  return <div className="row ">
    <div className="col-12">
      <ApplicationView item={app} />
      {!app?.inAppFeature && <>
        {versions.length === 0 && <>
          Not find versions.
        </>}
      </>}
      {app?.inAppFeature && <>
        In-App Feature 로 등록된 앱은 버전이 관리되지 않습니다.
      </>}
    </div>
    {!app?.inAppFeature && (tabcode === "basic" || !tabcode) && <>
      <div className="col-12">
        <h3 className="mt-4">Versions ({tableData.length})</h3>
        {tableData.slice(((page - 1) * 10), ((page - 1) * 10) + 10).length > 0 && <div className="card">
          {tableData.slice(((page - 1) * 10), ((page - 1) * 10) + 10).map((x, idx) => <div className="border-bottom" key={'ver-' + idx}>
            <div className="card-body d-flex justify-content-between align-items-center">
              <div>
                <div className="w-100px"> {x.published} </div>
                <h4 className="my-0">{x.version_name} <small className="mx-2">{x.vtype}</small> </h4>
                <small>Created : {x.created}</small>
                {x.authusers?.length > 0 && <>
                  <div className="fw-bolder">Authorized users</div>
                  {x.authusers}
                </>}
              </div>
              <div className="text-end">
                <div className="label">
                  <a href={x.file_link} target='_blank'>{x.file_name}</a> - {x.file_size}
                </div>
                {x.published_at && <small>Published : {x.published_at}</small>}
                {!x.published_at && <small>Not yet</small>}
                {x?.manualDocs?.length > 0 && <div><small>has {x?.manualDocs?.length} manuals</small></div>}
              </div>
            </div>
          </div>)}
        </div>}
        <div className="card-footer border-top pt-4">
          <div className="d-flex w-100 pb-0 mt-0 justify-content-between">
            {page > 1 ? <GrLinkPrevious className={(page > 1 ? 'raycur' : 'text-muted disabled')} size={24} onClick={() => setPage(page - 1)} /> : <div style={{ width: 24 }}>&nbsp;</div>}
            <div className="text-center fw-bolder fs-4">{tableData.length}</div>
            {(page < Math.ceil(tableData.length / 10)) ? <GrLinkNext className={(page < Math.ceil(tableData.length / 4) ? 'raycur' : 'text-muted disabled')} size={24} onClick={() => setPage(page + 1)} /> : <div style={{ width: 24 }}>&nbsp;</div>}
          </div>
        </div>
        {/*versions.length > 0 && <div className="card">
            <div className="card-body noborder">
              <RAYTable
                tableHeaders={ApplicationVersionTableHeader}
                tableData={tableData}
                useSort
                usePaginate={true}
              />
            </div>
          </div>*/}
      </div>
    </>}
  </div>
}

const mapState = (state) => {
  const isLogin = state.AuthReducer.isLogin;
  const apps = state.ApplicationReducer.apps;
  const appVersions = state.ApplicationVersionReducer.appVersions;
  return { isLogin, apps, appVersions };
};

const mapDispatch = (dispatch) => ({
  GetApplicationVersions: (appName) => dispatch(ApplicationVersionAction.GetApplicationVersions(appName))
})

export default connect(mapState, mapDispatch)(ApplicationHome);
