import _ from 'lodash';
import { AliasConstant } from '../reducers';
import { CommonAction } from './CommonAction';

const CheckEmailAlias = () => async (dispatch) => {
    try {
        const payload = {
            type: "query",
            region: "ap-northeast-2",
            params: {
                TableName: "rayrnd-applications",
                IndexName: "type-name-index",
                KeyConditionExpression: "#type = :type",
                ExpressionAttributeNames: { "#type": "type" },
                ExpressionAttributeValues: { ":type": "alias:email" },
            }
        };
        const list = await CommonAction.CallDB(payload);
        if (Array.isArray(list)) {
            dispatch({ type: AliasConstant.SET_EMAIL_ALIAS, items: list });
        }
        return list;
    } catch (error) {
        console.log("ERROR[CheckEmailAlias]", error?.toString());
    }
};

const UpdateEmailAliasItem = (data) => async (dispatch) => {
    try {
        const item = _.cloneDeep(data);
        if ((item?.type && item?.type !== "alias:email") || !data?.name) {
            return false;
        }
        item.type = item.type || "alias:email";
        const itemKey = {
            name: item.name,
            type: item.type,
        };
        const updateExpression = CommonAction.GetUpdateExpression(item);
        if (!updateExpression) {
            return;
        }
        const payload = {
            type: "update",
            region: "ap-northeast-2",
            params: {
                TableName: "rayrnd-applications",
                Key: itemKey,
                ...updateExpression,
                ReturnValues: "ALL_NEW",
            }
        };
        const ret = await CommonAction.CallDB(payload);
        if (ret?.Attributes && ret?.Attributes?.name === item.name) {
            dispatch({ type: AliasConstant.ADD_EMAIL_ALIAS, item: ret.Attributes });
            return ret.Attributes;
        }
    } catch (error) {
        console.log("ERROR[UpdateEmailAliasItem]", error?.toString());
    }
}


export const AliasAction = {
    CheckEmailAlias,
    UpdateEmailAliasItem,
};
