import _ from "lodash";

const initialState = { 
	item: [],
	items: [],
	requestedUserIds: [],
	pipeRecentCommitIds: {},
};

export const UserConstant = {
	SET_USERS: "ACTION_USER_SET_USERS",
	SET_USER: "ACTION_USER_SET_USER",
	REMOVE_USER: "ACTION_USER_REMOVE_USER",
	SET_PIPE_RECENT_COMMIT_IDS: "ACTION_USER_SET_PIPE_RECENT_COMMIT_IDS",
	ADD_REQUEST_USER_ID: "ACTION_USER_ADD_REQUEST_USER_ID",
};

export const UserReducer = (state = initialState, action) => {
    switch (action.type) {
		case UserConstant.ADD_REQUEST_USER_ID:
			return {
				...state,
				requestedUserIds: [...state.requestedUserIds, action._id],
			};
		case UserConstant.SET_PIPE_RECENT_COMMIT_IDS:
			return {...state,
				pipeRecentCommitIds: action.item
			}
		case UserConstant.REMOVE_USER:
			return {...state,
				item: [],
				items: state.items.filter(x => x.sub !== action.sub)
			}
		case UserConstant.SET_USERS:
			return {...state,
				items: action.items
			}
		case UserConstant.SET_USER:
			const detail = action.items.find(x => x.type === 'info:detail');
			const setUsers = () => {
				const existUser = state.items?.find(x => x.sub === detail?.sub);
				if (existUser) {
					return state.items.map(x => x.sub === detail?.sub ? { ...x, ...detail } : x);
				}
				return [...state.items, detail];
			}
			return {...state,
				item: action.items,
				items: setUsers(),
			}
        default:
            return state
    }
}