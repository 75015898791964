import { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import RAYButton from "../common/RAYButton";
import { AppConstant } from "../../redux/reducers";
import { athenaQuery, athenaGetAll, athenaGet } from "../../libs/athena2";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Query, Update, Delete } from "../../libs/dynamo";
import { FaSortDown, FaSortUp } from "react-icons/fa";
import moment from "moment";
import RAYTextField from "../common/RAYTextField";
import { COUNTRIES } from "../../constants";
import AppLogCodeModal from "./AppLogCodeModal";
import { Tooltip as RAYTooltip } from "react-tooltip";

const AppLogs = ({ app, item, ltype, logfilter }) => {
	const { appName } = useParams();
    const [data, setData] = useState([]);
    const [orderby, setOrderBy] = useState({ order : "st", desc : true });
    const [pg, setPg] = useState(0);
    const [wh, setWh] = useState("");
    const [query, setQuery] = useState("");
    const [loading, setLoading] = useState(true);
    const [qerr, setQErr] = useState(false);
    const [nextTokens, setNextTokens] = useState([]);
    const [codeModal, showCodeModal] = useState({ show : false });
    const [codes, setCodes] = useState([]);
    const [nextToken, setNextToken] = useState("");
    const [showhelpquery, setshowhelpquery] = useState(localStorage.getItem("showhelpquery"));
    const [modal, setModal] = useState({
        shoe : false
    });
    const dispatch = useDispatch();

    useEffect(() => {
		if(appName)
			getCodes();
    }, [appName]);

    useEffect(() => {
		if(ltype.from === "user"){
			dispatch({ type: AppConstant.SET_SW_LOG_FILTER, filter : { filter : "" }});
		}
    }, [ltype]);

    useEffect(() => {
		setQuery(logfilter.filter || "");
        getQuery();
    }, [logfilter]);

    const getCodes = async() => {
        const params = {
            TableName: "rayrnd-applications",
            KeyConditions: {
                name: { ComparisonOperator: "EQ", AttributeValueList: [appName], },
                type: { ComparisonOperator: "BEGINS_WITH", AttributeValueList: ["code:"], }
            },
        };
        const ret = await Query(params);
		console.log(params);
		console.log(ret);
		setCodes(ret.Items);
    }

    const getTableBody = (obj) => {
        return <div className="me-2">
            <table className="table table-bordered">
                <tbody>
                    <tr>
                        <th>Key</th>
                        <th>Value</th>
                    </tr>
                    {Object.keys(obj).filter(x => x !== "st").map(x => <tr key={x}>
                        <td>{x}</td>
                        <td style={{ wordBreak: "break-all" }}>{obj[x]}</td>
                    </tr>) }
                </tbody>
            </table>
        </div>
    }

    const showModal = async (x) => {
		console.log(x);
        dispatch({ type: AppConstant.SET_MODAL_SHOW, payload: { 
            title : <>{x.st} (st : {x.sto})</>,
            body : getTableBody(x),
            footer : <></>
        } });
    }

	const codeInfo = (x) => {
		const cinfo = codes.find(c => c.type === "code:" + x.cd);
		if(cinfo){
			return <>
				<span className="cursor-pointer fw-bolder" data-tooltip-id={cinfo.type} onClick={() => showCodeModal({ 
					show : true,
					item : { code : x.cd, desc : "", appname : appName }
				}) }>{x.cd}
				</span>
				<RAYTooltip id={cinfo.type} place="top" content={cinfo.desc} />
			</>
		}else{
			const regex = /[^\w\s-]/;
			return <span className="cursor-pointer" style={{ color : "brown", textDecoration : regex.test(x.cd) ? "line-through" : ""  }} onClick={() => showCodeModal({ 
				show : true,
				item : { code : x.cd, desc : "", appname : appName }
			}) }>{x.cd}</span>
		}
	}

    const getQuery = async () => {
        setLoading(true);
        const q = "select * from rayteams_" + ltype.code + "s_logs " + 
                " where tp= '" + item.alkey+ "' " +
				(logfilter.filter ? " and " + logfilter.filter : " " ) + 
                " order by " + orderby.order + " " + (orderby.desc ? " desc " : "");
        console.log(q);
        //const ret = await athenaQuery({ qid : "ddcafe17-33c7-49ec-a697-1da110cb423e", nextToken : "AUhGmaIfjQfPcAVu1j3+m2DUp1FBNYPVp6YmqbV/sTo9DxTCHeJJd2h0z4pCfjPa6nYlnVQpxYetZEWazzSfClpLCHLX" });
        const ret = await athenaQuery({ q, withdata : true });
        if(ret?.status !== "success"){
            dispatch({ type: AppConstant.CLEAR_LOAD_STACK });
            setQErr(wh ? true : false);
            setLoading(false);
            return;
        }
        setQErr(false);
        setNextTokens([""]);
        setPg(0);
        setNextToken({
            qid : ret.data.qid,
            token : ret.data.token
        });
        getNewData(ret.data.items);
        setLoading(false);
    }

	const updateCodes = (newitem) => {
		if(newitem?.type){
			if(codes.find(x => x.type === newitem.type)){
				setCodes(codes.map(x => x.type === newitem.type ? newitem : x));
			}else{
				setCodes([...codes, newitem]);
			}
		}
		showCodeModal({ show : false });
	}

	console.log(codes);

    const getNewData = (items) => {
        const newdata = items.map(x => ({...x, 
            l : moment.unix(x.st/1000).utc().format('LLL'), 
            sto : x.st,
            st : moment.unix(x.st/1000).utc().format('YYYY-MM-DD HH:mm:ss'),
            sess : x.si,
            si : x.si,
            cc : x.cc ? ( x.cc != "UNDEFINED" ? COUNTRIES.find(c => c.countryCode == x.cc).name + "(" + x.cc + ")" : x.cc ) : "",
            ymd : moment.unix(x.st/1000).utc().format('YYYY-MM-DD')
        }));
        dispatch({ type: AppConstant.CLEAR_LOAD_STACK });
        setData(newdata.map(x => ({...x, mg : <button className='btn btn-sm btn-info' onClick={() => showModal(x)}>View</button>})));
    }

    const goNext = async (nt) => {
        const ret = await athenaQuery({ qid : nextToken.qid, nextToken : nt ? nextTokens[nt - 1] : nextToken.token });
        if(!nt){
            setNextTokens([...nextTokens, ret.data.token]);
            setNextToken({ qid : ret.data.qid, token : ret.data.token });
            setPg(nextTokens.length);
        }else{
            console.log(nextTokens);
            setPg(nt - 1);
        }
        getNewData(ret.data.items);
        // setData(newdata);
    }

	const goSearch = () => {
        dispatch({ type: AppConstant.SET_SW_LOG_FILTER, filter : { filter : query }});
	}

	const getTd = (item, h) => {
		if(h === "cd"){
			return codeInfo(item);
		}else if(h === "ud"){
			return item[h] === "UNDEFINED" ? 
				<>{item[h]}</> : 
				<a href={"https://hq.rayteams.com/UsersInfo/" + item[h]} target="_blank" className="cursor-pointer fw-bolder" >{item[h]}</a>;
		}else if(h === "rp"){
			return item[h] === "UNDEFINED" ? 
				<>{item[h]}</> : 
				<a href={"https://hq.rayteams.com/RayTeams?rip=" + item[h]} target="_blank" className="cursor-pointer fw-bolder" >{item[h]}</a>;
		}else{
			return <>{item[h]}</>
		}
	}

    const aLogHeader = [
        { Header: 'GUID(id)', accessor: 'id', },
        { Header: 'USER(ud)', accessor: 'ud', },
        { Header: 'SESSION(si)', accessor: 'si', },
        { Header: 'Action(cd)', accessor: 'cd', },
        { Header: 'Country(cc)', accessor: 'cc', },
        { Header: 'Version(vr)', accessor: 'vr', },
        { Header: 'IP(rp)', accessor: 'rp', },
        { Header: 'Time(st)', accessor: 'st', Type : "DATE" },
        { Header: 'View', accessor: 'mg', Type : "LOG" },
    ];

    return <>
		<div className="d-flex justify-content-between mb-1">
			<RAYTextField value={query} size={"sm"} onChange={(e) => setQuery(e.target.value)} placeholder="Enter filter" style={qerr ? {
				background : "red", color : "white"
			} : {
				background : "white", color : "black"
			}} />
			<RAYButton
				disabled={loading}
				label={loading ? "Searching..." : "Search"}
				color="primary"
				onClick={() => goSearch()} />
		</div>
		{!showhelpquery && <div className="bg-light p-2" style={{ fontSize: 14 }}>
			<div className="d-flex justify-content-between">
				<div>
					<div>Example :</div> 
					<div> - cd = 'PATIENT_GET_PROJECT' and rp != '1.240.245.162' and st &gt; 1704429405000</div>
					<div> - cd like 'PATIENT_%' and st &lt; 1704429405000</div>
				</div>
				<span 
					onClick={() => { localStorage.setItem("showhelpquery", "Y"); setshowhelpquery("Y")}}
					className="cursor-pointer">Do not display again</span>
			</div>
		</div>}
		{!loading && <>
			{nextToken.token && <div className="d-flex justify-content-end mt-4">
				<div>
					{nextTokens.map((x, idx) => <>
						{pg === idx && <span style={{ margin : 4 }} className="fw-bolder">{idx + 1}</span>}
						{pg !== idx && <span style={{ margin : 4 }} className="cursor-pointer" onClick={() => goNext(idx + 1)}>{idx + 1}</span>}
					</>)}
					{nextToken.token && <>
						<span style={{ margin : 4 }}>...</span>
						<span className="cursor-pointer" onClick={() => goNext()}>Next</span>
					</>}
				</div>
			</div>}
			<table className="table" style={{ fontSize : 12 }}>
				<thead>
					<tr>
						{aLogHeader.map((x, idx) => <th key={"h-" + idx + "-" + x.Header}>
							{/*onClick={() => setOrderBy({ order : x.accessor, desc : (orderby.order === x.accessor ? !orderby.desc : true )})}>*/}
							{x.Header}
							{orderby.order === x.accessor && orderby.desc && <FaSortDown style={{ float : "right", marginTop : 2 }} /> }
							{orderby.order === x.accessor && !orderby.desc && <FaSortUp style={{ float : "right", marginTop : 2 }} /> }
						</th>)}
					</tr>
				</thead>
				<tbody>
					{data.map((x, idx) => <tr key={"d-" + idx + "-" + x.st}>
						{aLogHeader.map((h, idx) => <td key={"hd-" + idx + "-" + h.Header}>{getTd(x, h.accessor)}</td>)}
					</tr>)}
				</tbody>
			</table>
		</>}
        <AppLogCodeModal show={codeModal.show} item={codeModal.item} callback={(e) => updateCodes(e)} />
    </>
}

const mapState = (state) => {
	const logfilter = state.AppReducer.logfilter;
	return { logfilter };
};

const mapDispatch = (dispatch) => ({
})

export default connect(mapState, mapDispatch)(AppLogs);