import React, { useCallback, useMemo } from "react";
import { Badge } from "react-bootstrap";
import Select from 'react-select';
import { COUNTRIES } from "../../constants";

const RAYSelectCountry = ({ placeholder, value, callbackOnChange, multi = false,
    disabled = false, readOnly = false, style, css = '', menuPlacement = 'auto',
    clearable = true, viewMode = false, slotExistDesc, slotNoneExistDesc }) => {
    const countryList = useMemo(() => COUNTRIES.map(x => ({ value: x.countryCode, label: x.name })), []);
    const val = useMemo(() => {
        let _value;
        if (typeof (value) === "string") {
            _value = countryList.find(x => x.value === value);
        } else {
            _value = countryList.filter(x => value.includes(x.value));
        }
        if (multi && typeof (value) === "string") {
            _value = [_value];
        }
        return _value;
    }, [countryList, value, multi]);

    const onChangeHandler = useCallback((data) => {
        callbackOnChange && callbackOnChange(data?.map(x => x.value) || (multi ? [] : ""));
    }, [callbackOnChange, multi])

    return <div style={{ ...style }} className={css}>
        {viewMode ? <>
            {val?.length === 0 && <>
                {slotNoneExistDesc && <>{slotNoneExistDesc}</>}
                {!slotNoneExistDesc && <i>No Items</i>}
            </>}
            {val?.length > 0 && <>
                {slotExistDesc && <>{slotExistDesc}</>}
                <div className="d-flex align-items-center gap-1">
                    {val.map(x => <React.Fragment key={x.value}>
                        <Badge>
                            {x.label}
                        </Badge>
                    </React.Fragment>)}
                </div>
            </>}
        </> : <>
            <Select
                isMulti={multi}
                closeMenuOnSelect={!multi}
                value={val}
                menuPlacement={menuPlacement}
                options={countryList}
                isDisabled={disabled}
                readOnly={readOnly}
                isClearable={clearable}
                onChange={onChangeHandler}
                placeholder={placeholder}
                style={{ marginTop: '11px', zIndex: 2 }}
                classNamePrefix="ray-select"
            />
        </>}
    </div>
}

export default RAYSelectCountry;
