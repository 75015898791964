import { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { ApplicationAction, ApplicationVersionAction } from "../../redux/actions";
import RAYButton from "../common/RAYButton";
import { TYPE } from "../../constants/appTerms";
import RAYSelectAdmin from "../../components/common/RAYSelectAdmin";
import RAYTextField from "../../components/common/RAYTextField";
import RAYSelect from "../../components/common/RAYSelect";
import RAYTable from "../../components/common/RAYTable";
import AppIUDChart from "../../components/application/AppIUDChart";
import AppClickDownloadChart from "../../components/application/AppClickDownloadChart";
import { Query } from "../../libs/dynamo";
import { poolColors } from "../../libs/functions";
import { LANGUAGE } from "../../constants";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Colors } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import moment from "moment";

const VIEW_TYPE = {
    VERSIONS: 'versions',
    IUD: 'iud',
    CLICKDWN: 'click_download',
};

const APP_MAPS = {
    raylink: 'RAYTeams',
    rayface: 'RAYFace',
};

const AppSettingAnal = ({ item, UpdateApplication }) => {
    const storedLanguages = useSelector(state => state.ApplicationReducer.languages);
    const [viewType, setViewType] = useState(VIEW_TYPE.VERSIONS);
    const [pre, setPre] = useState(30);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [dataChart, setDataChart] = useState({});
    const dispatch = useDispatch();

    ChartJS.register(ArcElement, Tooltip, Legend);

    useEffect(() => {
        getQuery();
    }, []);

    useEffect(() => {
        console.log(item);
    }, [item]);

    const getKey = (key) => {
        const nKey = APP_MAPS[key?.toLowerCase()];
        return nKey ? nKey : key?.toLowerCase();
    }

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const refreshPre = () => {
        getQuery();
    }

    const getQuery = async () => {
        const params = {
            TableName : "rayteams-info",
            IndexName : "sk-ver-index",
            KeyConditions: {
                sk: {
                    ComparisonOperator: "EQ",
                    AttributeValueList: ["prg:" + getKey(item.name)],
                }
            },
            FilterConditions: {
                updated: {
                    ComparisonOperator: "LT",
                    AttributeValueList: [new Date().getTime() - (pre * 24 * 3600 * 1000)],
                }
            },
        };
        const ret = await Query(params);
        let kv = {};
        let sumTotal = 0;
        const pretime = new Date().getTime() - (pre * 24 * 3600 * 1000);
        ret.Items.filter(x => x.updated > pretime).map(x => {
            const nk = x.sk.replace("prg:", "");
            kv[x.ver] = kv[x.ver] ? kv[x.ver] + 1 : 1
            sumTotal++;
        });
        setTotal(sumTotal);
        const sortable = Object.entries(kv)
            .sort(([,a],[,b]) => b-a)
            .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});
        setDataChart({
            labels: Object.keys(sortable).map(x => x),
            datasets: [{
                label: '# of Version',
                data: Object.values(sortable).map(x => x),
                backgroundColor : poolColors(Object.keys(sortable).length),
                borderWidth: 1,
            }],
        });
        setData(Object.keys(sortable).map(x => (
            { ver : x, cnt : numberWithCommas(sortable[x]) }
        )));
    }

    const updateTransUser = async () => {
        await ApplicationAction.UpdateApplication({
            appName : item.name,
            data : { tusers : data.tusers },
        })
    }

    const aLogHeader = [
        { Header: 'Version', accessor: 'ver', },
        { Header: 'Count', accessor: 'cnt', },
    ];

    return <>
        <div className="col-12 px-4 pb-4">
            <ul className="nav nav-tabs">
                <li className="nav-item">
                    <a className={"nav-link " + (viewType === VIEW_TYPE.VERSIONS ? "active": "")} 
                        onClick={() => setViewType(VIEW_TYPE.VERSIONS)} aria-current="page" href="#">Versions</a>
                </li>
                <li className="nav-item">
                    <a className={"nav-link " + (viewType === VIEW_TYPE.IUD ? "active": "")} 
                        onClick={() => setViewType(VIEW_TYPE.IUD)} aria-current="page" href="#">Install/Update</a>
                </li>
                <li className="nav-item">
                    <a className={"nav-link " + (viewType === VIEW_TYPE.CLICKDWN ? "active": "")} 
                        onClick={() => setViewType(VIEW_TYPE.CLICKDWN)} aria-current="page" href="#">Download</a>
                </li>
            </ul>
        </div>
        {viewType === VIEW_TYPE.CLICKDWN && <AppClickDownloadChart item={item} />}
        {viewType === VIEW_TYPE.IUD && <AppIUDChart item={item} />}
        {viewType === VIEW_TYPE.VERSIONS && <div>
            <div className="">
                <div className="d-flex justify-content-between">
                    <h4>
                        Versions <small className="ms-2 fs-6">Total : {total}, 최근 {pre}일내에 업데이트된 데이터 기준</small>
                    </h4>
                    <div className="d-flex align-items-center">
                        <div style={{ width : "30px" }}>최근 </div>
                        <RAYTextField value={pre} size={"sm"}  style={{ width : "50px", marginRight : 4 }}onChange={(e) => setPre(e.target.value)} />
                        일
                        <RAYButton label={"조회"} color={"primary"} size={"sm"} style={{ marginLeft : 4 }} onClick={() => refreshPre()} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-5">
                        {dataChart.datasets && <Pie data={dataChart} />}
                    </div>
                    <div className="col-7">
                        <RAYTable
                            tableHeaders={aLogHeader}
                            tableData={data}
                            initPageSize={100}
                            useSort
                        />
                    </div>
                </div>
            </div>
        </div>}
    </>
}

export default AppSettingAnal;
