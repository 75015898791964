import React, { useCallback, useMemo, useState } from "react";
import { Collapse, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { AliasAction } from "../../redux/actions";
import RAYButton from "../common/RAYButton";
import { RAYIconSetting } from "../common/RAYIcon";
import EmailAliasEditModal from "./EmailAliasEditModal";

const DefaultButton = (props) => {
    return <RAYButton
        color={"primary"}
        label={"Import e-mail group"}
        {...props}
    />
};

const EmailAliasImportModal = ({ auth, slotButton, emailAlias, callbackSelect }) => {
    const [show, setShow] = useState(false);
    const list = useMemo(() => emailAlias?.filter(x => x.activated && !x.isDel).sort((a, b) => a.alias < b.alias ? -1 : 1), [emailAlias]);
    const [selectedItemName, setSelectedItemName] = useState(list[0]?.name ?? "");
    const selectedItem = useMemo(() => {
        return list.find(x => x.name === selectedItemName) ?? {};
    }, [selectedItemName, list]);
    const hasAccountRole = useMemo(() => auth?.is_account_manager || auth?.is_super_admin, [auth]);

    const closeModal = useCallback(() => {
        setShow(false);
        setSelectedItemName("");
    }, []);

    const handlerSelect = () => {
        callbackSelect && callbackSelect(selectedItem);
        closeModal();
    };

    return <>
        {slotButton && slotButton?.props ? <>
            {React.cloneElement(slotButton, { ...slotButton.props, onClick: () => setShow(true) })}
        </> : <>
            <DefaultButton onClick={() => setShow(true)} />
        </>}
        <Modal
            show={show}
            centered={true}
            onHide={closeModal}
        >
            <Modal.Header closeButton={true}>
                <Modal.Title>
                    Import e-mail Group
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className="d-flex flex-column gap-2">
                        {list?.map(x => {
                            const isSelected = selectedItem?.name === x.name;
                            return <div key={`${x.name}-${x.created || x.updated}`}>
                                <div
                                    className={`px-2 py-1 cursor-pointer ${isSelected ? "text-white bg-primary" : "border"}`}
                                    style={{
                                        borderRadius: 6,
                                    }}
                                    onClick={() => setSelectedItemName(x.name)}
                                >
                                    <span
                                        style={{
                                            fontSize: 16,
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {x.alias}
                                    </span>
                                </div>
                                <Collapse in={isSelected}>
                                    <div>
                                        <div
                                            className="px-2 py-2 d-flex flex-wrap gap-1"
                                            style={{
                                                maxHeight: 200,
                                                overflowY: "auto",
                                                borderBottom: "1px solid #ddd",
                                            }}
                                        >
                                            <div className="w-100 d-flex align-items-center justify-content-end gap-2">
                                                <div>
                                                    Total {selectedItem?.emails?.length} e-mails
                                                </div>
                                                {hasAccountRole && <>
                                                    <EmailAliasEditModal
                                                        item={selectedItem}
                                                        slotButton={<RAYIconSetting className="cursor-pointer" />}
                                                    />
                                                </>}
                                            </div>
                                            {selectedItem?.emails?.map(y => <div
                                                key={y}
                                                style={{
                                                    width: "48%"
                                                }}
                                            >
                                                {y}
                                            </div>)}
                                        </div>
                                    </div>
                                </Collapse>
                            </div>
                        })}
                    </div>
                    <div className="d-flex align-items-center justify-content-end mt-3">
                        <RAYButton
                            label={`Select ${selectedItem?.alias || ""}`}
                            color={selectedItem?.name ? "primary" : "secondary"}
                            onClick={handlerSelect}
                        />
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    </>
};

const mapState = (state) => {
    const auth = state.AuthReducer.user;
    const emailAlias = state.AliasReducer.emailAlias;
    return { auth, emailAlias };
};

const mapDispatch = (dispatch) => ({
    UpdateEmailAliasItem: (data) => dispatch(AliasAction.UpdateEmailAliasItem(data)),
});

export default connect(mapState, mapDispatch)(EmailAliasImportModal);
