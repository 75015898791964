import { extraAxios } from '../../libs/axios';
import { AppConfig } from '../../libs/config';
import { Store } from '../../store';
import { Buffer } from 'buffer';

const callDynamoVersion = "V2";
const publicKeyBase64 = "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA3PFSnnbICzJlkNhNyERAY4KQbdFZQj7mBJ5lfaRQWRiO1XFXDFt69E6f+GE7FMXKUy7VYbykopbNRjl+2pFKOjpW4CX9hHbPgNkH4HDq11Bk8suloWh7hxJMOjKZHXgPM6nypjARcbE5SNHgmChiAwS4Y+WofawRC4PH1lQw++JG1kuQ45sponY3tVhlS9Y6tiY7jXHORqDDFqwcEbn4Gb+CONNo5gt/tIX6M73wWOi8YB6byAxHt5405gg5mhq5r/SIjLTrNSIswShWuaWfW9jzgRHSX4N5WzZAh1MsqMFPjBY/AcjstFtbPuLUOv742oGjOoJ0ve3qm18MlTqI2QIDAQAB";

const CallDBV1 = async (payload, callback, forceRAY = false) => {
    try {
        if (!payload?.type || !payload.params) {
            return false;
        }
        const apiDomain = AppConfig.REACT_APP_INFO_API;
        const apiUrl = `${apiDomain}/common/dynamo`;
        payload.region = payload.region || Store.getState().AuthReducer.user?.region;
        const ret = await extraAxios.post(apiUrl, { data: payload });
        callback && typeof callback === "function" && callback(ret.data);
        return ret?.data
    } catch (error) {
        //
        console.log("ERROR[CallDB]", error?.response?.data || error?.toString());
        return false;
    }
};

const CallDBV2 = async (originPayload, callback, forceRAY = false) => {
    try {
        if (!originPayload?.type || !originPayload.params) {
            return false;
        }

        const payload = await getCallDBPayload(originPayload);
        if (window?.location?.origin.startsWith("http://localhost:")) {
            payload.debug = originPayload;
        }
        // const apiDomain = getInfoApiUrl(forceRAY);
        const apiDomain = AppConfig.REACT_APP_INFO_API;
        const apiUrl = `${apiDomain}/common/calldynamo`;
        // console.log("apiUrl", apiUrl);
        const ret = await extraAxios.post(apiUrl, payload);
        callback && typeof callback === "function" && callback(ret.data);
        return ret?.data;
    } catch (error) {
        //
        console.log("ERROR[CallDBV2]", error?.response?.data || error?.toString());
        return false;
    }
};

const CallDB = async (payload, callback, forceRAY = false) => {
    try {
        const supportCrypto = !!window?.crypto?.subtle;
        if (callDynamoVersion === "V1" || !supportCrypto) {
            return await CallDBV1(payload, callback, forceRAY);
        } else if (callDynamoVersion === "V2" && supportCrypto) {
            return await CallDBV2(payload, callback, forceRAY);
        }
        return [];
    } catch (error) {
        //
        console.log("ERROR[CallDB]", error?.response?.data || error?.toString());
        return false;
    }
};

async function getCallDBPayload(originPayload) {
    const inputText = JSON.stringify(originPayload);
    let texts = _.chunk(inputText, 150);
    texts = texts.map(x => x.join(""));
    const publicKey = await importPublicKey(publicKeyBase64);
    const payloadData = [];
    for (const text of texts) {
        const encryptText = await encryptMessage(text, publicKey);
        payloadData.push(encryptText);
    }
    const payload = {
        data: payloadData,
    };
    return payload;
}

async function importPublicKey(publicKeyBase64) {
    const publicKeyBuffer = Uint8Array.from(Buffer.from(publicKeyBase64, "base64"));
    const publicKey = await window.crypto.subtle.importKey(
        "spki",
        publicKeyBuffer,
        {
            name: "RSA-OAEP",
            hash: "SHA-256"
        },
        true,
        ["encrypt"]
    );
    return publicKey;
}
async function encryptMessage(inputText, key) {
    let enc = new TextEncoder();
    let encoded = enc.encode(inputText);
    const cipherText = await window.crypto.subtle.encrypt(
        {
            name: "RSA-OAEP"
        },
        key,
        encoded
    );
    let buffer = new Uint8Array(cipherText);
    let hex = Buffer.from(buffer).toString('hex');
    return hex;
}

const GetUpdateExpression = (obj, options = { hk: "name", sk: "type" }) => {
    obj = _.cloneDeep(obj);
    if (!obj || typeof obj !== "object") {
        return null;
    }
    if (obj.hasOwnProperty(options.hk)) {
        delete obj[options.hk];
    }
    if (obj.hasOwnProperty(options.sk)) {
        delete obj[options.sk];;
    }
    const keys = Object.keys(obj);
    if (keys.length === 0) {
        return null;
    }
    const exps = [];
    const names = {};
    const values = {};
    for (const key of keys) {
        const val = obj[key];
        let labelKey = `#${key}`;
        let labelVal = `:${key}`;
        if (key.includes(".")) {
            const labelArr = key.split(".");
            const labelKeyArr = [];
            for (const _label of labelArr) {
                names[`#${_label}`] = _label;
                labelKeyArr.push(`#${_label}`);
            }
            labelKey = labelKeyArr.join(".");
            labelVal = ":" + labelArr.join("_");
            values[labelVal] = val;
        } else {
            names[labelKey] = key;
            values[labelVal] = val;
        }
        exps.push(`${labelKey} = ${labelVal}`);
    }
    const result = {
        UpdateExpression: `SET ${exps.join(", ")}`,
        ExpressionAttributeNames: names,
        ExpressionAttributeValues: values,
    };
    return result;
};

export const CommonAction = {
    CallDB,
    GetUpdateExpression,
};